<template>
  <div>
    <PagesServicesUnitInspection/>
  </div>
</template>

<script>
import PagesServicesUnitInspection from "components/pages/services/PagesServicesUnitInspection";
export default {
  name: "inspection",
  components: {PagesServicesUnitInspection},
}
</script>

<style scoped>

</style>
