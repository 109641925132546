<template>
  <div>
    <router-view v-if="$route.name === 'notes_new'"/>
    <div class="d-flex justify-center">
      <v-sheet width="100%" max-width="650">
        <v-card v-if="$route.name !== 'notes'" class="pa-4 border">
          <v-form ref="form" lazy-validation>
            {{ controls.filter(i => i.name === $route.name)[0].title }}
            <div class="grid mt-4">
              <div>
                <div class="font12">
                  {{ t['date'] }} {{ date }}
                </div>
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="date"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        dense
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        :rules="[v => !!v || t['required_field']]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="date"
                      no-title
                      scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>

              <DevInput
                  :text="t['sum']"
                  v-model="sum"
                  type="Number"
                  :rules="[v => !!v || t['required_field']]"
              >
                <v-icon slot="append-outer" class="mt-1">mdi-currency-eur</v-icon>
              </DevInput>
            </div>
            <DevInput
                :text="t['note']"
                v-model="note"
                text-area
                hide-details
            />
          </v-form>
        </v-card>
        <div class="py-4">
          <v-btn @click="addNew" class="primary">
            <v-icon>mdi-plus</v-icon>
            {{ t['add'] }}
          </v-btn>
        </div>
        <v-card class="pa-4 border">
          {{ t['history'] }}
          <div v-if="$route.name === 'notes'">
            <div v-for="(item, i) in serviceActionList.data" :key="i">
              <v-card @click="openNote(item.id)" v-if="item.service_control.type === $route.name" class="pa-4 mt-4 border click">
                <div class="font12">
                  {{ item.created_at.split('/').join('.') }}
                </div>
                {{ JSON.parse(item.data).name }}
              </v-card>
            </div>
          </div>
          <div v-else>
            <div v-for="(item, i) in serviceActionList.data" :key="i">
              <v-card v-if="item.service_control.type === $route.name" class="pa-4 mt-4 border">
                <div class="d-flex justify-space-between">
                  <div class="font12">
                    {{ JSON.parse(item.data).split('-').join('.') }}
                  </div>
                  <div class="font12 primary--text">
                    {{ item.sum }} €
                  </div>
                </div>
                <div class="mt-2">
                  {{ item.notes }}
                </div>
              </v-card>
            </div>
          </div>


        </v-card>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DevInput from "components/dev/DevInput";

export default {
  name: "PagesServicesUnitInspection",
  components: {DevInput},
  data: () => ({
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu: false,
    sum: '',
    note: ''
  }),
  computed: {
    t() {
      return Object.assign(
          this.$store.state.t[this.$route.params.language]['fields'],
          this.$store.state.t[this.$route.params.language]['form'],
          this.$store.state.t[this.$route.params.language]['buttons'],
      )
    },
    controls() {
      return Object.assign(
          this.$store.state.t[this.$route.params.language]['service_control']
      )
    },
    ...mapGetters("serviceAction", ["serviceActionList"]),
    ...mapGetters("service", ["serviceUnit"])
  },
  created() {
    this.onServiceActionList({number: '0'})
  },
  methods: {
    ...mapActions("serviceAction", ["onServiceActionList", "onServiceActionCreate"]),

    addNew() {
      if (this.$route.name === 'notes') {
        this.$router.push({name: 'notes_new'})
      } else {
        if (this.$refs.form.validate()) {
          let temp = {
            service_id: this.serviceUnit.data[0].service.id,
            service_control_id: this.$route.params['control'],
            sum: this.sum,
            notes: this.note,
            data: JSON.stringify(this.date)
          }
          console.log(temp)
          this.onServiceActionCreate(temp)
          this.$router.push({name: 'unit_id'})
        }
      }
    },

    openNote(id) {
      this.$router.push({name: 'notes_id', params: {note: id}})
    }
  }
}
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
}

.click {
  cursor: pointer !important;
  &:hover {
    border-color: $primary !important;
  }
}
</style>
